import React, { useEffect, useMemo, useState } from 'react'
import { PageProps } from 'gatsby'
import SVG from 'react-inlinesvg'

import { EmailNotificationLayout } from '../../../components/email-notification'
import { varClass } from '../../../components/shared'

export default function DowngradePlan(props: PageProps) {
  const [type, setType] = useState('')

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    setType(params?.get('type') ?? '')
  }, [])

  const data = useMemo(() => {
    switch (type) {
      case '2':
        return {
          title: <>
            Your plan has been downgraded to <span className='text-boldest'>[PLAN]</span>
          </>,
          img: '/images/email-notifications/down-grade-plan/2.svg',
          desc: <>
            You've downgraded from <span className='text-boldest'>[OLD PLAN]</span> to <span
            className='text-boldest'>[PLAN]</span>.
            As a result, there may have been changes to your plan's usage limits and features.
          </>,
          features: [],
          featureIcon: '',
          planLimits: [],
          btnLabel: 'View Subscription page',
        }
      case '3':
        return {
          title: 'Your Heylink account has been deleted 🥺',
          img: '/images/email-notifications/down-grade-plan/3.svg',
          desc: <>
            <span className='text-boldest'>We are sad to see you go!</span><br />
            Your Heylink account has been deleted successfully. All related data will be taken off our servers within 7
            days. If this was a mistake, please contact us promptly.
          </>,
          features: [],
          featureIcon: '',
          planLimits: [],
          btnLabel: 'Contact us',
        }
      case '4':
        return {
          title: <>
            Your plan has been downgraded to <span className='text-boldest'>[PLAN]</span>
          </>,
          img: '/images/email-notifications/down-grade-plan/4.svg',
          desc: <>
            You've downgraded from <span className='text-boldest'>[OLD PLAN]</span> to <span
            className='text-boldest'>[PLAN]</span>.
            As a result, there may have been changes to your plan's usage limits and features.
          </>,
          features: [],
          featureIcon: '',
          planLimits: [
            {
              name: 'Records',
              value: '250.000',
              icon: '/images/email-notifications/down-grade-plan/click-icon.svg'
            },
            {
              name: 'Commission',
              value: '50.000',
              icon: '/images/email-notifications/down-grade-plan/dolar-icon.svg'
            },
            {
              name: 'Pageviews',
              value: '50.000',
              icon: '/images/email-notifications/down-grade-plan/view-icon.svg'
            },
            {
              name: 'Users',
              value: '3',
              icon: '/images/email-notifications/down-grade-plan/user-icon.svg'
            }
          ],
          btnLabel: 'View Subscription page',
        }
      case '5':
        return {
          title: <>
            Your plan has been downgraded to <span className='text-boldest'>[PLAN]</span>
          </>,
          img: '/images/email-notifications/down-grade-plan/2.svg',
          desc: <>
            You've downgraded from <span className='text-boldest'>[OLD PLAN]</span> to <span
            className='text-boldest'>[PLAN]</span>.
            As a result, there may have been changes to your plan's usage limits and features.
          </>,
          features: [
            'Unified reporting across',
            'Multiple affiliate networks',
            'Full API access Integration with adcertisers',
            'White-label affiliate networks',
            'Google-shopping feed Sub-affiliates'
          ],
          featureIcon: '/images/email-notifications/complete-update/feature-icon.svg',
          planLimits: [
            {
              name: 'Records',
              value: '250.000',
              icon: '/images/email-notifications/down-grade-plan/click-icon.svg'
            },
            {
              name: 'Commission',
              value: '50.000',
              icon: '/images/email-notifications/down-grade-plan/dolar-icon.svg'
            },
            {
              name: 'Pageviews',
              value: '50.000',
              icon: '/images/email-notifications/down-grade-plan/view-icon.svg'
            },
            {
              name: 'Users',
              value: '3',
              icon: '/images/email-notifications/down-grade-plan/user-icon.svg'
            }
          ],
          btnLabel: 'Go to Subscription page',
        }

      default:
        return {
          title: <>
            Your plan has been downgraded to <span className='text-boldest'>[PLAN]</span>
          </>,
          img: '/images/email-notifications/down-grade-plan/1.svg',
          desc: <>
            You've downgraded from <span className='text-boldest'>[OLD PLAN]</span> to <span
            className='text-boldest'>[PLAN]</span>.
            As a result, there may have been changes to your plan's usage limits and features.
          </>,
          features: [],
          featureIcon: '',
          planLimits: [],
          btnLabel: 'View Subscription page ',
        }
    }
  }, [type])
  return <>
    <EmailNotificationLayout>
      <div className='column column-fill column-middle column-center'>
        <div className='column gap-30 column-middle column-center'>
          <h1 className='text-bold text-dark text-largiestest text-center m-y-0 max-w-374'>{data.title}</h1>
          <div className='row row-center row-middle'>
            <SVG src={data.img} />
          </div>
          <p className='text-smaller text-dark text-center m-y-0 max-w-333'>
            {data.desc}
          </p>
        </div>
        <div className='m-t-20'>
          <button className='btn btn-primary p-3 text-smaller text-bold'>{data.btnLabel}</button>
        </div>
        {!!data.features.length &&
          <div className='m-t-40 column gap-2'>
            <h2 className='text-bolder text-big text-dark m-y-0'>Your new plan features</h2>
            {data.features.map((feature, index) =>
              <div key={feature + index} className='row row-fill row-center'>
                <SVG width={14} height={14} src={data.featureIcon} />
                <span className='text-dark text-9-3'>{feature}</span>
              </div>,
            )
            }
          </div>
        }
        {!!data.planLimits.length &&
          <div className={varClass({
            'column gap-20 column-center': true,
            'm-t-30': !!data.features.length,
            'm-t-50': !data.features.length,
          })}>
            <h2 className='text-bolder text-big text-dark text-center m-y-0'>Your new plan limits</h2>
            <div className='row gap-3'>
              {data.planLimits.map((p, index) => <div key={index} className='column gap-15 min-w-87 text-smaller'>
                <div>{p.name}</div>
                <div className='row gap-15 row-center'>
                  <SVG width={16} height={16} src={p.icon} />
                  <span className='text-light text-bold'>{p.value}</span>
                </div>
              </div>)}
            </div>
          </div>
        }
      </div>
    </EmailNotificationLayout>
  </>

}